import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AccommodationTypeEnum, CostEstimatorService, ExistingHeatingTypeEnum, HousingAgeEnum, OwnerStatusEnum } from '@effy-tech/angular-common';
import { Store } from '@ngrx/store';
import {
  Nullable,
  VulcanExistingHeatingElectricType,
  VulcanExistingHeatingGasType,
  VulcanExistingHeatingOilType,
  VulcanExistingHeatingTypeParcoursSerenity,
  VulcanHousingAgeValues,
  VulcanHousingTypeValues,
  VulcanPrecarityAnahLevelValues
} from '@prc-lib-core';
import { AnswserKeys, HouseholdIncome, TimingProject, UserStatus } from '@prc-serenity-models/keys.model';
import { AppState } from '@prc-serenity-store/app-state.model';
import { getAnswersWorksData } from '@prc-serenity-store/selectors/answers-works.selector';
import { getAnswersData } from '@prc-serenity-store/selectors/answers.selector';
import { DateUtil } from '@prc-serenity-utils/date.util';
import { VulcanUtils } from '@prc-serenity-utils/vulcan/vulcan.utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CostEstimatorHelperService {
  protected costEstimatorService = inject(CostEstimatorService);
  protected answers$ = this.store.select(getAnswersData);
  protected answersWorks$ = this.store.select(getAnswersWorksData);

  protected readonly precarityLevelAnah$: Observable<VulcanPrecarityAnahLevelValues> = this.answers$.pipe(
    map(answsers => answsers[AnswserKeys.HouseholdIncome] as HouseholdIncome),
    map(householdIncome => VulcanUtils.getPrecarityAnahLevel(householdIncome))
  );

  protected readonly housingAge$ = this.answers$.pipe(
    map(answsers => answsers[AnswserKeys.HousingAge] as VulcanHousingAgeValues),
    map(housingAge => this.getHousingAge(housingAge))
  );

  protected zipCode$ = this.answers$.pipe(
    map(answers =>
      answers[AnswserKeys.WorksAddress] ? answers[AnswserKeys.WorksAddress]?.postalCode : answers[AnswserKeys.HousingZipCode]?.code_postal
    )
  ) as Observable<string>;

  protected readonly ownerStatus$: Observable<OwnerStatusEnum> = this.store.select(getAnswersData).pipe(
    map(answsers => answsers[AnswserKeys.UserStatus]),
    map(userStatus => this.getOwnerStatus(userStatus as UserStatus))
  );

  protected readonly accomodationType$: Observable<AccommodationTypeEnum> = this.store.select(getAnswersData).pipe(
    map(answsers => answsers[AnswserKeys.HousingType] as VulcanHousingTypeValues),
    map(housingType => this.getHousingType(housingType))
  );

  protected readonly existingHeatingType$ = this.answers$.pipe(
    map(answsers => answsers[AnswserKeys.ExistingHeatingType]),
    map(existingHeatingType => this.getExistingHeatingType(existingHeatingType as VulcanExistingHeatingTypeParcoursSerenity))
  );

  protected readonly commitmentDate$: Observable<string | null> = this.answers$.pipe(
    map(answers => answers[AnswserKeys.TimingProject]),
    map(timing => CostEstimatorHelperService.getCommitmentDate(timing))
  );

  constructor(protected readonly store: Store<AppState>, protected readonly httpClient: HttpClient) {}

  static getCommitmentDate(timing: Nullable<TimingProject>): string | null {
    const date = new Date();

    if (timing === TimingProject.DontKnow) return null;

    if (timing === TimingProject.Next6Months) {
      date.setMonth(date.getMonth() + 3);
      date.setDate(1);
    }

    return DateUtil.dateToYearMonthDay(date);
  }

  private getOwnerStatus(userStatus: UserStatus): OwnerStatusEnum {
    const OWNER_STATUS_MAPPER: { [key in UserStatus]: OwnerStatusEnum } = {
      [UserStatus.OwnerOccupied]: OwnerStatusEnum.ResidentOwner,
      [UserStatus.SecondHome]: OwnerStatusEnum.SecondaryResidenceOwner,
      [UserStatus.OwnerLessor]: OwnerStatusEnum.LeasingOwner,
      [UserStatus.Tenant]: OwnerStatusEnum.Tenant,
    };

    return OWNER_STATUS_MAPPER[userStatus];
  }

  private getHousingType(housingType: VulcanHousingTypeValues): AccommodationTypeEnum {
    const HOUSING_TYPE_MAPPER: { [key in VulcanHousingTypeValues]: AccommodationTypeEnum } = {
      [VulcanHousingTypeValues.Appartment]: AccommodationTypeEnum.Apartment,
      [VulcanHousingTypeValues.House]: AccommodationTypeEnum.House,
    };

    return HOUSING_TYPE_MAPPER[housingType];
  }

  private getHousingAge(housingAge: VulcanHousingAgeValues): HousingAgeEnum {
    const HOUSING_AGE_MAPPER: { [key in VulcanHousingAgeValues]: HousingAgeEnum } = {
      [VulcanHousingAgeValues.LessThanTwoYears]: HousingAgeEnum.LessThan2Years,
      [VulcanHousingAgeValues.BetweenTwoAndFifteenYears]: HousingAgeEnum.Between2And15Years,
      [VulcanHousingAgeValues.MoreThanFifteenYears]: HousingAgeEnum.MoreThan15Years,
    };

    return HOUSING_AGE_MAPPER[housingAge];
  }

  private getExistingHeatingType(existingHeatingType: VulcanExistingHeatingTypeParcoursSerenity): ExistingHeatingTypeEnum {
    const EXISTING_HEATING_TYPE_MAPPER: { [key in VulcanExistingHeatingTypeParcoursSerenity]: ExistingHeatingTypeEnum } = {
      [VulcanExistingHeatingGasType.CondensingGasBoiler]: ExistingHeatingTypeEnum.CondensingGasBoiler,
      [VulcanExistingHeatingGasType.NonCondensingGasBoiler]: ExistingHeatingTypeEnum.NonCondensingGasBoiler,
      [VulcanExistingHeatingGasType.GasHeating]: ExistingHeatingTypeEnum.GasHeating,
      [VulcanExistingHeatingElectricType.ElectricBoiler]: ExistingHeatingTypeEnum.ElectricBoiler,
      [VulcanExistingHeatingElectricType.ElectricHeating]: ExistingHeatingTypeEnum.ElectricHeating,
      [VulcanExistingHeatingElectricType.LowEfficiencyElectricHeating]: ExistingHeatingTypeEnum.LowEfficiencyElectricHeating,
      [VulcanExistingHeatingOilType.CondensingOilBoiler]: ExistingHeatingTypeEnum.CondensingOilBoiler,
      [VulcanExistingHeatingOilType.NonCondensingOilBoiler]: ExistingHeatingTypeEnum.NonCondensingOilBoiler,
      [VulcanExistingHeatingOilType.OilHeating]: ExistingHeatingTypeEnum.OilHeating
    };

    return EXISTING_HEATING_TYPE_MAPPER[existingHeatingType];
  }
}
