import { Injectable, inject } from '@angular/core';
import { EstimateApi, EstimateOfferKey } from '@effy-tech/angular-common';
import { Store } from '@ngrx/store';
import {
  CostEstimatorOfferWithOfferWorkType,
  VulcanWorkTypeValues,
  WorkInfo,
  WorkOfferType,
  WorkTypeValues,
  getTitleByWorkType
} from '@prc-lib-core';
import { CostEstimatorAirWaterService } from '@prc-serenity-cost-estimator-services/air-water/cost-estimator-air-water.service';
import { CostEstimatorAtticsInsulationService } from '@prc-serenity-cost-estimator-services/attics/cost-estimator-attics.service';
import { CostEstimatorWallInsulationService } from '@prc-serenity-cost-estimator-services/wall-insulation/cost-estimator-wall-insulation.service';
import { AppState } from '@prc-serenity-store/app-state.model';
import { getAnswersWorksData } from '@prc-serenity-store/selectors/answers-works.selector';
import { getAnswersData } from '@prc-serenity-store/selectors/answers.selector';
import { CommonAssetsUtil } from '@prc-serenity-utils/common-assets.util';
import { VulcanUtils } from '@prc-serenity-utils/vulcan/vulcan.utils';
import { Observable, combineLatest, map, mergeMap, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResultsService {
  private readonly costEstimatorWallInsulationService = inject(CostEstimatorWallInsulationService);
  private readonly costEstimatorAtticsInsulationService = inject(CostEstimatorAtticsInsulationService);
  private readonly costEstimatorAirWaterHeatPumpService = inject(CostEstimatorAirWaterService);

  vulcanWorksTypes$: Observable<VulcanWorkTypeValues[]> = combineLatest([
    this.store.select(getAnswersData),
    this.store.select(getAnswersWorksData),
  ]).pipe(map(([answers, answersWorks]) => VulcanUtils.getWorksTypes(answers, answersWorks)));

  constructor(private readonly store: Store<AppState>) {}

  estimate$(): Observable<CostEstimatorOfferWithOfferWorkType | null> {
    // @TODO : Modify to handle multiple works
    return this.vulcanWorksTypes$.pipe(
      map(worksTypes => worksTypes[0]),
      mergeMap(workType => this.getEstimateByWorkType$(workType))
    );
  }

  private getEstimateByWorkType$(workType: VulcanWorkTypeValues): Observable<CostEstimatorOfferWithOfferWorkType | null> {
    switch (workType) {
      case VulcanWorkTypeValues.AirWaterHeatPump:
        return this.estimateAirWaterHeatPump();
      case VulcanWorkTypeValues.AtticsInsulation:
        return this.estimateAtticsInsulation();
      case VulcanWorkTypeValues.WallInsulation:
        return this.estimateWallInsulation();
      case VulcanWorkTypeValues.PhotovoltaicPanel:
        return this.estimatePhotovoltaicPanel();
      default:
        return of(null);
    }
  }

  private mapEstimateToCostEstimatorOfferWithOfferWorkType(
    offer: EstimateApi,
    workType: WorkTypeValues
  ): CostEstimatorOfferWithOfferWorkType {
    return {
      offerWorkType: {
        workType: workType,
        offerType: EstimateOfferKey.Serenity,
      },
      [EstimateOfferKey.Serenity]: offer.offre_serenite,
      [EstimateOfferKey.Liberty]: offer.offre_liberte,
    };
  }

  private estimateAirWaterHeatPump(): Observable<CostEstimatorOfferWithOfferWorkType> {
    return this.costEstimatorAirWaterHeatPumpService
      .estimate$()
      .pipe(map(costEstimator => this.mapEstimateToCostEstimatorOfferWithOfferWorkType(costEstimator, WorkTypeValues.AirWaterHeatPump)));
  }

  private estimateAtticsInsulation(): Observable<CostEstimatorOfferWithOfferWorkType> {
    return this.costEstimatorAtticsInsulationService
      .estimate$()
      .pipe(map(costEstimator => this.mapEstimateToCostEstimatorOfferWithOfferWorkType(costEstimator, WorkTypeValues.AtticInsulation)));
  }

  private estimateWallInsulation(): Observable<CostEstimatorOfferWithOfferWorkType> {
    return this.costEstimatorWallInsulationService
      .estimate$()
      .pipe(map(costEstimator => this.mapEstimateToCostEstimatorOfferWithOfferWorkType(costEstimator, WorkTypeValues.WallInsulation)));
  }

  private estimatePhotovoltaicPanel(): Observable<CostEstimatorOfferWithOfferWorkType> {
    const baseFakeOffer = {
      premiums: {
        renov: null,
        cee: null,
      },
      total_cost: null,
      remaining_cost: null,
      total_premium: null,
      quantity: null,
    };

    const fakeOffer = {
      offerWorkType: {
        workType: WorkTypeValues.PhotovoltaicPanel,
        offerType: WorkOfferType.Serenity,
      },
      [WorkOfferType.Liberty]: {
        ...baseFakeOffer,
        unit_cost: {
          average: null,
          min: null,
          max: null,
        },
      },
      [WorkOfferType.Serenity]: {
        ...baseFakeOffer,
        unit_cost: {
          average: null,
          min: null,
          max: null,
        },
      },
    } as unknown as CostEstimatorOfferWithOfferWorkType;
    return of(fakeOffer);
  }

  getPhotovoltaicPanelWorkInfo(): WorkInfo {
    return {
      picto: CommonAssetsUtil.getPictoWorksUrl('photovoltaic.svg'),
      title: getTitleByWorkType(WorkTypeValues.PhotovoltaicPanel),
      isSolarePanel: true,
      offerType: WorkOfferType.Serenity,
      amountEffy: 0,
      primeEffy: 0,
      mprAmount: 0,
      leftToPay: 0,
      listTitle: 'Nous gérons vos travaux de A à Z et garantissons leur qualité !',
      listItems: [
        'Installation de vos panneaux en 1 journée par un partenaire expert',
        'Jusqu’à 40% d’économies sur votre facture',
        'Des panneaux solaires garantis jusqu’à 30 ans',
      ],
    };
  }

  private getAirWaterWorkInfo(estimate: CostEstimatorOfferWithOfferWorkType): WorkInfo {
    return this.mapEstimateToWorkInfo(estimate, WorkTypeValues.AirWaterHeatPump, 'air-water-heat-pump');
  }

  private getAtticInsulationWorkInfo(estimate: CostEstimatorOfferWithOfferWorkType): WorkInfo {
    return this.mapEstimateToWorkInfo(estimate, WorkTypeValues.AtticInsulation, 'attics-insulation');
  }

  private getWallInsulationWorkInfo(estimate: CostEstimatorOfferWithOfferWorkType): WorkInfo {
    return this.mapEstimateToWorkInfo(estimate, WorkTypeValues.WallInsulation, 'walls-insulation');
  }

  getWorksInfosFromEstimate(estimate: CostEstimatorOfferWithOfferWorkType): WorkInfo[] {
    switch (estimate.offerWorkType.workType) {
      case WorkTypeValues.PhotovoltaicPanel:
        return [this.getPhotovoltaicPanelWorkInfo()];
      case WorkTypeValues.AirWaterHeatPump:
        return [this.getAirWaterWorkInfo(estimate)];
      case WorkTypeValues.AtticInsulation:
        return [this.getAtticInsulationWorkInfo(estimate)];
      case WorkTypeValues.WallInsulation:
        return [this.getWallInsulationWorkInfo(estimate)];
      default:
        return [];
    }
  }

  private mapEstimateToWorkInfo(
    estimate: CostEstimatorOfferWithOfferWorkType,
    workType: WorkTypeValues,
    pictoFilenameWithoutExtension: string
  ): WorkInfo {
    const offer = estimate.offre_serenite;

    return {
      picto: CommonAssetsUtil.getPictoWorksUrl(`${pictoFilenameWithoutExtension}.svg`),
      title: getTitleByWorkType(workType),
      isSolarePanel: false,
      offerType: WorkOfferType.Serenity,
      amountEffy: offer.total_cost,
      primeEffy: offer.premiums.cee > 0 ? -offer.premiums.cee : offer.premiums.cee,
      mprAmount: offer.premiums.renov > 0 ? -offer.premiums.renov : offer.premiums.renov,
      leftToPay: offer.remaining_cost,
      listTitle: 'Nous gérons vos travaux de A à Z et garantissons leur qualité !',
      listItems: [
        'Nous sélectionnons pour vous un artisan RGE de confiance',
        'Nous nous occupons des demandes d’aides et les déduisons du devis',
        'Nous sommes votre interlocuteur unique pendant tout le projet',
      ],
    };
  }
}
