import { Injectable } from '@angular/core';
import { AtticsInsulationRequestModel, AtticsTypeEnum, CostEstimatorKeys, EstimateApi, PrecarityLevel } from '@effy-tech/angular-common';
import {
  VulcanAtticsType
} from '@prc-lib-core';
import { CostEstimatorHelperService } from '@prc-serenity-cost-estimator-services/cost-estimator.service';
import { AnswserKeys } from '@prc-serenity-models/keys.model';
import { Observable, combineLatest, map, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CostEstimatorAtticsInsulationService extends CostEstimatorHelperService {
  atticsType$ = this.answersWorks$.pipe(
    map(answersWorks => answersWorks[AnswserKeys.AtticsType]),
    map(atticsType => this.getAtticsType(atticsType as VulcanAtticsType))
  );
  atticsSurface$ = this.answersWorks$.pipe(map(answersWorks => answersWorks[AnswserKeys.AtticsSurface])) as Observable<number>;

  estimate$(): Observable<EstimateApi> {
    return combineLatest([
      this.atticsType$,
      this.commitmentDate$,
      this.existingHeatingType$,
      this.housingAge$,
      this.atticsSurface$,
      this.precarityLevelAnah$,
      this.ownerStatus$,
      this.zipCode$,
    ]).pipe(
      mergeMap(([atticsType, commitmentDate, existingHeatingType, housingAge, atticsSurface, precarityLevelAnah, ownerStatus, zipCode]) => {
        const body: AtticsInsulationRequestModel = {
          ...(commitmentDate ? { [CostEstimatorKeys.CommitmentDate]: commitmentDate } : {}),
          [CostEstimatorKeys.AtticsType]: atticsType,
          [CostEstimatorKeys.ExistingHeatingType]: existingHeatingType,
          [CostEstimatorKeys.HousingAge]: housingAge,
          [CostEstimatorKeys.InsulatingSurface]: atticsSurface,
          [CostEstimatorKeys.PrecarityLevel]: precarityLevelAnah as unknown as PrecarityLevel,
          [CostEstimatorKeys.OwnerStatus]: ownerStatus,
          [CostEstimatorKeys.ZipCode]: zipCode,
        };

        return this.costEstimatorService.estimateAtticsInsulation(body);
      })
    );
  }

  private getAtticsType(atticsType: VulcanAtticsType): AtticsTypeEnum {
    const ATTICS_TYPE_MAPPER: { [key in VulcanAtticsType]: AtticsTypeEnum } = {
      [VulcanAtticsType.HabitableAttics]: AtticsTypeEnum.Indoor,
      [VulcanAtticsType.UnoccupiedAttics]: AtticsTypeEnum.Lost,
    };

    return ATTICS_TYPE_MAPPER[atticsType];
  }
}
