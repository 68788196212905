import { Injectable } from '@angular/core';
import { CostEstimatorKeys, EstimateApi, PrecarityLevel, WallInsulationRequestModel, WallInsulationTypeEnum, WallInsulationWorkprojectInsulationTypeEnum } from '@effy-tech/angular-common';
import { CostEstimatorHelperService } from '@prc-serenity-cost-estimator-services/cost-estimator.service';
import { AnswserKeys } from '@prc-serenity-models/keys.model';
import { Observable, combineLatest, map, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CostEstimatorWallInsulationService extends CostEstimatorHelperService {
  insulationSurface$ = this.answersWorks$.pipe(map(answersWorks => answersWorks[AnswserKeys.WallsSurface])) as Observable<number>;

  estimate$(): Observable<EstimateApi> {
    return combineLatest([
      this.insulationSurface$,
      this.ownerStatus$,
      this.housingAge$,
      this.precarityLevelAnah$,
      this.zipCode$,
      this.existingHeatingType$,
      this.commitmentDate$,
    ]).pipe(
      mergeMap(([insulationSurface, ownerStatus, housingAge, precarityLevel, zipCode, existingHeatingType, commitmentDate]) => {
        const body: WallInsulationRequestModel = {
          [CostEstimatorKeys.WallInsulationType]: WallInsulationTypeEnum.Coated,
          [CostEstimatorKeys.WallInsulationWorkprojectInsulationType]: WallInsulationWorkprojectInsulationTypeEnum.External,
          [CostEstimatorKeys.InsulatingSurface]: insulationSurface,
          [CostEstimatorKeys.OwnerStatus]: ownerStatus,
          [CostEstimatorKeys.HousingAge]: housingAge,
          [CostEstimatorKeys.PrecarityLevel]: precarityLevel as unknown as PrecarityLevel,
          [CostEstimatorKeys.ZipCode]: zipCode,
          [CostEstimatorKeys.ExistingHeatingType]: existingHeatingType,
          ...(commitmentDate ? { [CostEstimatorKeys.CommitmentDate]: commitmentDate } : {}),
        };

        return this.costEstimatorService.estimateWallInsulation(body);
      })
    );
  }
}
