import { WorkTypeValues } from "../models/works.model";
import { Nullable } from "../types";

export const TITLE_BY_WORK_TYPE: { [key in WorkTypeValues]?: string } = {
  [WorkTypeValues.AirAirHeatPump]: 'Climatisation réversible (Pompe à chaleur Air/Air)',
  [WorkTypeValues.AirWaterHeatPump]: 'Pompe à chaleur air/eau',
  [WorkTypeValues.AtticInsulation]: 'Isolation des combles',
  [WorkTypeValues.CombinedSolarSystem]: 'Système solaire combiné',
  [WorkTypeValues.CondensingFuelBoiler]: 'Chaudière fioul à condensation',
  [WorkTypeValues.CondensingGasBoiler]: 'Chaudière gaz à condensation',
  [WorkTypeValues.DoubleGlazing]: 'Remplacement des fenêtres',
  [WorkTypeValues.DoubleFlowCMV]: 'VMC double-flux',
  [WorkTypeValues.FireplaceInsert]: 'Insert cheminée',
  [WorkTypeValues.FloorInsulation]: 'Isolation du sous-sol',
  [WorkTypeValues.GeothermalHeatPump]: 'Pompe à chaleur géothermique',
  [WorkTypeValues.GlobalRenovation]: 'Rénovation globale',
  [WorkTypeValues.HybridHeatPump]: 'Pompe à chaleur hybride',
  [WorkTypeValues.PhotovoltaicPanel]: 'Panneaux solaires photovoltaïques',
  [WorkTypeValues.RoofTerrace]: 'Isolation de la toiture-terrasse',
  [WorkTypeValues.SolarWaterHeater]: 'Chauffe-eau solaire individuel',
  [WorkTypeValues.StorageHeater]: 'Radiateurs électriques',
  [WorkTypeValues.ThermodynamicWaterHeater]: 'Chauffe-eau thermodynamique',
  [WorkTypeValues.WallInsulation]: 'Isolation des murs',
  [WorkTypeValues.WoodPelletBoiler]: 'Chaudière à bois',
  [WorkTypeValues.WoodStove]: 'Poêle à bois',
};

export function getTitleByWorkType(workType: Nullable<WorkTypeValues>): string {
  return workType ? TITLE_BY_WORK_TYPE[workType] as string : '';
}
