import { OperationCodeValues } from './operation-codes.model';
import { VulcanWorkTypeValues } from './vulcan.model';

export interface WorkModel {
  value: VulcanWorkTypeValues;
  operationCode: OperationCodeValues;
  inApartment: boolean;
}

export interface WorksCategoryModel {
  label: string;
  works: WorkModel[];
}

export enum WorkTypeValues {
  Aerovoltaic = 'aerovoltaic',
  AirAirHeatPump = 'air_air_heat_pump',
  AirWaterHeatPump = 'air_water_heat_pump',
  AtticInsulation = 'attic_insulation',
  Boiler = 'boiler', // Fake work type
  CombinedSolarSystem = 'combined_solar_system',
  CondensingFuelBoiler = 'condensing_oil_boiler',
  CondensingGasBoiler = 'condensing_gas_boiler',
  DoubleFlowCMV = 'double_flow_cmv',
  DoubleGlazing = 'double_glazing',
  FireplaceInsert = 'fireplace_insert',
  FloorInsulation = 'floor_insulation',
  GeothermalHeatPump = 'geothermal_heat_pump',
  GlobalRenovation = 'global_renovation',
  HeatPump = 'heat_pump',
  HybridHeatPump = 'hybrid_heat_pump',
  HybridSolar = 'hybrid_solar',
  PhotovoltaicPanel = 'photovoltaic_panel',
  RoofTerrace = 'roof_terrace',
  SolarWaterHeater = 'solar_water_heater',
  StorageHeater = 'storage_heater',
  ThermodynamicWaterHeater = 'thermodynamic_water_heater',
  WallInsulation = 'wall_insulation',
  WoodPelletBoiler = 'wood_pellet_boiler',
  WoodStove = 'wood_stove',
  EnergyAudit = 'energy_audit',
}

export enum WorkOfferType {
  Serenity = 'Sérénité',
  Liberty = 'Liberté',
}
export interface WorkInfo {
  picto: string;
  title: string;
  isSolarePanel: boolean;
  offerType: WorkOfferType;
  amountEffy: number;
  primeEffy: number;
  mprAmount: number;
  leftToPay: number;
  listTitle: string;
  listItems: string[];
}
