export class DateUtil {
  static dateToYearMonthDay(date: Date): string {
    return [
      date.getFullYear(), // AAAA
      date.getMonth() + 1, // MM
      date.getDate(), // DD
    ]
      .map((nbr, i) => (i > 0 ? ('0' + nbr).slice(-2) : nbr)) // add 0 if month and/or day < 10
      .join('-');
  }
}
