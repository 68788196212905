import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AirWaterHeatPumpRequestModel, CostEstimatorKeys, EstimateApi, PrecarityLevel } from '@effy-tech/angular-common';
import { Store } from '@ngrx/store';
import { CostEstimatorHelperService } from '@prc-serenity-cost-estimator-services/cost-estimator.service';
import { AnswserKeys } from '@prc-serenity-models/keys.model';
import { AppState } from '@prc-serenity-store/app-state.model';
import { Observable, combineLatest, map, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CostEstimatorAirWaterService extends CostEstimatorHelperService {
  heatedSurface$ = this.answersWorks$.pipe(map(answersWorks => answersWorks[AnswserKeys.AirWaterHeatPumpSurface])) as Observable<number>;

  constructor(protected override readonly httpClient: HttpClient, protected override readonly store: Store<AppState>) {
    super(store, httpClient);
  }

  estimate$(): Observable<EstimateApi> {
    return combineLatest([
      this.accomodationType$,
      this.housingAge$,
      this.heatedSurface$,
      this.ownerStatus$,
      this.precarityLevelAnah$,
      this.zipCode$,
      this.existingHeatingType$,
      this.commitmentDate$,
    ]).pipe(
      mergeMap(
        ([accomodationType, housingAge, heatedSurface, ownerStatus, precarityLevel, zipCode, existingHeatingType, commitmentDate]) => {
          const body: AirWaterHeatPumpRequestModel = {
            [CostEstimatorKeys.HousingAge]: housingAge,
            [CostEstimatorKeys.AccommodationType]: accomodationType,
            [CostEstimatorKeys.HeatedSurface]: heatedSurface,
            [CostEstimatorKeys.OwnerStatus]: ownerStatus,
            [CostEstimatorKeys.PrecarityLevel]: precarityLevel as unknown as PrecarityLevel,
            [CostEstimatorKeys.WithEcs]: false,
            [CostEstimatorKeys.ZipCode]: zipCode,
            [CostEstimatorKeys.ExistingHeatingType]: existingHeatingType,
            ...(commitmentDate ? { [CostEstimatorKeys.CommitmentDate]: commitmentDate } : {}),
          };
          return this.costEstimatorService.estimateAirWaterHeatPump(body);
        }
      )
    );
  }
}
